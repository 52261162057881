// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dev-descriptions-laundryview-mdx": () => import("./../../../src/pages/dev/descriptions/laundryview.mdx" /* webpackChunkName: "component---src-pages-dev-descriptions-laundryview-mdx" */),
  "component---src-pages-dev-index-js": () => import("./../../../src/pages/dev/index.js" /* webpackChunkName: "component---src-pages-dev-index-js" */),
  "component---src-pages-dev-laundryview-js": () => import("./../../../src/pages/dev/laundryview.js" /* webpackChunkName: "component---src-pages-dev-laundryview-js" */),
  "component---src-pages-dev-mujik-js": () => import("./../../../src/pages/dev/mujik.js" /* webpackChunkName: "component---src-pages-dev-mujik-js" */),
  "component---src-pages-dev-printstats-js": () => import("./../../../src/pages/dev/printstats.js" /* webpackChunkName: "component---src-pages-dev-printstats-js" */),
  "component---src-pages-dev-spark-js": () => import("./../../../src/pages/dev/spark.js" /* webpackChunkName: "component---src-pages-dev-spark-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-uses-index-js": () => import("./../../../src/pages/uses/index.js" /* webpackChunkName: "component---src-pages-uses-index-js" */),
  "component---src-pages-uses-uses-mdx": () => import("./../../../src/pages/uses/uses.mdx" /* webpackChunkName: "component---src-pages-uses-uses-mdx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

